<template>
  <div
    class="fixed h-20 xl:max-w-md lg:max-w-md md:max-w-sm w-full bottom-0 flex flex-col items-center justify-center font-opensans px-4 shadow-md text-white"
    :class="this.isBagOpen ? 'z-10' : 'z-20'"
    style="
        background-image: url('https://maizalblobstorage.blob.core.windows.net/assets/maizal-catering-account-footer-bg.webp');
        background-repeat: no-repeat;
        background-size: cover;
      "
  >
    <ModalRight
      :showing="showModalLocationPickup"
      @close="showModalLocationPickup = false"
      :showSemiBg="true"
    >
      <OrderType @close="showModalLocationPickup = false" />
    </ModalRight>
    <div class="flex items-center justify-between text-xs w-full max-w-md">
      <router-link
        :to="$route.name !== 'home' ? '/' : ''"
        class="flex flex-col items-center space-y-1 cursor-pointer"
      >
        <HomeIcon color="text-maizal-yellow" size="w-6 h-6"/>
        <p>home</p>
      </router-link>
      <a
        href="https://catering.maizalgrill.com"
        class="flex flex-col items-center space-y-1 cursor-pointer"
      >
        <CateringIcon color="text-maizal-yellow" size="w-6 h-6"/>
        <p>catering</p>
    </a>
      <router-link
        to="/rewards"
        class="flex flex-col items-center space-y-1 cursor-pointer"
      >
        <RewardsIcon color="text-maizal-yellow" size="w-6 h-6"/>
        <p>rewards</p>
      </router-link>
      <a
        href="https://order.maizalgrill.com"
        class="flex flex-col items-center space-y-1 cursor-pointer"
      >
      <OrderNowIcon color="text-maizal-yellow" size="w-6 h-6"/>
      <p>order now</p>
    </a>
      <!-- <div
        v-if="Object.keys(this.location).length > 0"
        class="flex flex-col items-center space-y-1 cursor-pointer"
        @click="routeTo()"
      >
        <OrderNowIcon color="text-maizal-yellow" size="w-6 h-6"/>
        <p>order now</p>
      </div> -->
      <!-- <div
        v-else
        class="flex flex-col items-center space-y-1 cursor-pointer"
        @click="showModalLocationPickup = !showModalLocationPickup"
      >
        <OrderNowIcon color="text-maizal-yellow" size="w-6 h-6"/>
        <p>order now</p>
      </div> -->
    </div>
  </div>
</template>

<script>
import {
  authComputed,
  userComputed,
  siteMethods,
  bagComputed,
} from "@/state/helpers";
import OrderNowIcon from "@/components/icon/orderNowBottom.vue";
import RewardsIcon from "@/components/icon/rewardsBottom.vue";
import HomeIcon from "@/components/icon/homeMain.vue";
import CateringIcon from "@/components/icon/catering.vue";
import ModalRight from "@/components/modal/modalFullRight.vue";
import OrderType from "../../router/content/orderType.vue";
export default {
  components: {
    OrderNowIcon,
    RewardsIcon,
    HomeIcon,
    CateringIcon,
    OrderType,
    ModalRight,
  },
  props: {},
  data() {
    return {
      showModalLocationPickup: false,
    };
  },
  computed: {
    ...authComputed,
    ...userComputed,
    ...bagComputed,
  },
  methods: {
    ...siteMethods,
    closePage() {
      this.$emit("closePage");
    },
    // Method to route to Menu page
    routeTo() {
      try {
        if (this.orderTypeGlobal === "delivery") {
          this.$router
            .push(`/delivery/${this.location.id}/menu`)
            .catch((error) => {
              if (
                error.name !== "NavigationDuplicated" &&
                !error.message.includes(
                  "Avoided redundant navigation to current location"
                )
              ) {
                console.error();
              }
            });
        } else {
          this.$router.push(`/${this.location.slug}/menu`).catch((error) => {
            if (
              error.name !== "NavigationDuplicated" &&
              !error.message.includes(
                "Avoided redundant navigation to current location"
              )
            ) {
              console.error();
            }
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
