<template>
  <nav
    class="flex items-center justify-between fixed z-20 top-0 h-16 bg-white w-screen xl:px-4 font-opensans text-maizal-black"
  >
    <ModalTime
      :showing="chooseTimePageOpen"
      @close="closeChooseTimePage"
      :showSemiBg="false"
    >
      <ScheduleOrder @close="closeChooseTimePage" />
    </ModalTime>
    <Modal
      :showing="locationPageOpen"
      @close="closeLocationPage"
      :showSemiBg="true"
    >
      <OrderType @close="closeLocationPage" />
    </Modal>
    <div class="flex items-center space-x-4 px-4">
      <a href="/">
        <img
          class="h-8 lg:h-10 my-3"
          alt="home_logo"
          src="https://maizalblobstorage.blob.core.windows.net/banners/MaizalLogo.webp"
        />
      </a>
      <!-- <div class="border-maizal-black h-8 border-l-0.5"></div> -->
      <div class="cursor-pointer" v-if="this.currentRouteName !== 'checkout'">
        <!-- <div class="flex justify-between items-center space-x-2">
          <router-link v-if="loggedIn" to="/account" class="text-maizal-yellow">
            <UserIcon />
          </router-link>
          <UserNotLoggedInPanel v-else />
          <div class="text-xs flex flex-col">
            <div v-if="loggedIn" class="text-maizal-black">
              {{ first_name }}
            </div>
            <router-link v-else to="/login" class="text-maizal-yellow">
              {{ "sign in" }}
            </router-link>
          </div>
        </div> -->
        <!-- <div class="flex justify-between items-center space-x-2">
            
          
          <UserNotLoggedInPanel  />
         
        </div> -->
      </div>
    </div>
    <div
      v-if="this.currentRouteName !== 'checkout'"
      class="flex flex-col sm:flex-row items-center"
    >
      <div
        class="order-last md:order-none w-screen md:flex-grow md:items-center md:w-auto hidden md:flex"
        id="header-nav-content"
      >
        <ul
          class="list-reset md:flex justify-center items-center font-brandon font-normal text-maizal-black text-lg md:space-x-6 xl:space-x-24 mr-10"
        >
          <li class="cursor-pointer uppercase">
            <!-- <div  @click="routetowhitlabel">order</div> -->
            <!-- <a href="https://order.maizalgrill.com/">order</a> -->
          </li>
          <li class="cursor-pointer uppercase">
            <router-link to="/all-locations">locations</router-link>
          </li>
          <li class="cursor-pointer uppercase">
            <router-link to="/rewards">rewards</router-link>
          </li>
          <li class="cursor-pointer uppercase">
            <a href="https://catering.maizalgrill.com" target="_blank">catering</a>
          </li>
          <!-- <li class="cursor-pointer">
            <a href="https://maizal-catering-frontend-dev.azurewebsites.net/" target="_blank" >catering</a>
          </li> -->
        </ul>
      </div>
    </div>
    <div
      v-if="this.currentRouteName !== 'checkout'"
      class="flex space-x-4 items-center"
    >
      <!-- <div
        @click="openLocationPage"
        class="flex items-center justify-center text-xs space-x-0.5 font-thin font-opensans cursor-pointer my-1 rounded-xl px-2 py-1 bg-maizal-bg-yellow"
        v-if="Object.keys(this.location).length > 0 && orderTypeGlobal"
      >
        <LocationIcon size="w-6 h-6" />
        <div class="flex flex-col" v-if="orderTypeGlobal === 'pickup'">
          <p class="font-normal font-opensans">{{ orderTypeGlobal }} from</p>
          <div class="flex font-bold font-opensans">
            <p class="truncate text-ellipsis">
              {{
                Object.keys(this.location).length > 0
                  ? location.name.substring(0, 20)
                  : ""
              }}{{ location && location.name.length > 20 ? "..." : "" }}
            </p>
          </div>
        </div>
        <div class="flex flex-col" v-else-if="orderTypeGlobal === 'delivery'">
          <p class="font-normal font-opensans">{{ orderTypeGlobal }} to</p>
          <p class="flex font-bold font-opensans">
            {{
              delivery.streetAddress
                ? delivery.streetAddress.substring(0, 20)
                : ""
            }},
            {{ delivery.city }}
          </p>
        </div>
      </div> -->
      <!-- <div
        @click="openLocationPage"
        class="flex items-center justify-center text-xs space-x-0.5 font-thin font-opensans cursor-pointer my-1 bg-maizal-bg-yellow rounded-xl px-2 py-2"
        v-else
      >
        <NoLocationIcon size="w-6 h-6" />
        <div class="flex flex-col">
          <p class="font-bold font-opensans">find a maizal near me</p>
        </div>
      </div> -->
      <!-- <Bag
        class="cursor-pointer pl-6"
        :loggedIn="loggedIn"
        :username="loggedIn ? first_name : ''"
        :location="!isLocationEmpty ? location.name : 'find your location'"
      /> -->
      <a href="https://order.maizalgrill.com/" class="px-4"><Button themeColor="maizal-yellow" textToDisplay="Order now"/></a>
      
    </div>
    <div v-else class="pr-4">
      <!-- <Close @click.native="goBackToPreviousPage" size="w-4 h-4" /> -->
    </div>
  </nav>
</template>
<script>
// import Bag from "./Bag.vue";
// import UserIcon from "./UserIcon.vue";
import {
  authComputed,
  userComputed,
  userMethods,
  siteMethods,
  siteComputed,
} from "@/state/helpers";
// import Close from "@/components/icon/close.vue";
// import LocationIcon from "@/components/icon/location.vue";
// import NoLocationIcon from "@/components/icon/noLocation.vue";
// import UserNotLoggedInPanel from "./UserNotLoggedInPanel.vue";
import Button from "@/components/other/displayButton.vue";
import OrderType from "../../router/content/orderType.vue";
import Modal from "@/components/modal/modalFullRight.vue";
import ModalTime from "@/components/modal/modalFullRightTime.vue";
import ScheduleOrder from "@/components/userOptions/scheduleOrder.vue";
import geoLocationHelper from "@/_utils/geo-location.js";
export default {
  components: {
    // Bag,
    // UserIcon,
    // Close,
    // UserNotLoggedInPanel,
    // LocationIcon,
    // NoLocationIcon,
    Button,
    Modal,
    OrderType,
    ScheduleOrder,
    ModalTime,
  },
  computed: {
    ...authComputed,
    ...userComputed,
    ...siteComputed,
    currentRouteName() {
      return this.$route.name;
    },
  },

  data() {
    return {
      selectedOrderType: "",
      showModalLocationPickup: false,
      first_name: null,
    };
  },
  methods: {
    ...userMethods,
    ...siteMethods,
    sendToChooseLocation() {
      this.$router.push(`/location`);
    },
    close() {
      this.$router.go
      this.showModalLocationPickup = false;
    },
    routetowhitlabel(){
      this.$router.push()
    },
    goBackToPreviousPage() {
      this.$router.go(-1);
    },
    closeLocationPage() {
      this.setLocationPageOpen(false);
    },
    openLocationPage() {
      this.setLocationPageOpen(true);
    },
    closeChooseTimePage() {
      this.setTimePageOpen(false);
    },
    openTimePage() {
      this.setTimePageOpen(true);
    },
    // Method to route to Menu page
    routeTo() {
      try {
        if (!Object.keys(this.location).length > 0) {
          this.$router.push("/");
        } else if (this.orderTypeGlobal === "delivery") {
          this.$router
            .push(`/delivery/${this.location.id}/menu`)
            .catch((error) => {
              if (
                error.name !== "NavigationDuplicated" &&
                !error.message.includes(
                  "Avoided redundant navigation to current location"
                )
              ) {
                console.error();
              }
            });
        } else {
          this.$router.push(`/${this.location.slug}/menu`).catch((error) => {
            if (
              error.name !== "NavigationDuplicated" &&
              !error.message.includes(
                "Avoided redundant navigation to current location"
              )
            ) {
              console.error();
            }
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
  watch: {
    userBasicInformation: {
      handler(newValue) {
        if (Object.keys(newValue).length > 0) {
          this.first_name = newValue.user.first_name;
        }
      },
      deep: true,
    },
  },
  async mounted() {
    this.setCookies();
    this.selectedOrderType = this.orderTypeGlobal;
    if (this.loggedIn && this.account.access_token) {
      const userDetails = await this.setUserBasicInformation({
        username: this.account.user.email,
      });
      console.log(userDetails)
      this.first_name = this.account.user.first_name;
    }
    const options = {
      enableHighAccuracy: true,
      timeout: 10000,
      maximumAge: 0,
    };
    geoLocationHelper
      .getPosition(options)
      .then((position) => {
        this.setLatitude(position.coords.latitude);
        this.setLongitude(position.coords.longitude);
      })
      .catch((err) => {
        console.log(err);
      });
  },
};
</script>
